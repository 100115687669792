import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import Nav from '../Components/Nav';

function Profile() {
    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)

    /// use profits to display earnings too and also
    const [profits, setprofits] = useState(0)

    const [open, setOpen] = React.useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                    setloading(false)

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/");
                }
            });
        }
    }, []);


    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };

    return (
        <div>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="stylesheet" href="/stylesheets/output.css" />
            <link rel="stylesheet" href="/stylesheets/dashboard.css" />
            <title>Qfsnesere | Dashboard</title>
            {/* SMARTSUPP */}
            {/* Smartsupp Live Chat script */}
            <noscript> Powered by &lt;a href=“https://www.smartsupp.com” target=“_blank”&gt;Smartsupp&lt;/a&gt;</noscript>
            <div className="min-h-screen relative bg-white">
                {/*DASHBOARD NAVIGATION*/}
                <Nav />
                {/* Flash Messages */}
                <div className="flex flex-col justify-center items-center my-10">
                    <section className="flex flex-col items-center w-full md:w-4/6 my-4">
                        <div className="w-min rounded-full">
                            <div className="flex items-center justify-center bg-rose-50 w-12 h-12 rounded-full m-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth=".75" stroke="currentColor" className="w-16 h-16 text-black">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </div>
                        </div>
                        <div className="text-center">
                            <h3 className="text-2xl font-bold font-jarkata text-gray-900">
                                {userdetails.fullanme}
                            </h3>
                            <p className="text-gray-700 font-semibold text-base">{userdetails.email}</p>
                            <a className="text-blue-600 font-semibold underline hover:text-blue-200" href="settings">
                                Edit Profile
                            </a>
                        </div>
                    </section>
                    <section className="flex flex-col w-full md:w-4/6 my-4 ">
                        <div className="flex items-center justify-between my-4 pb-3 border-b border-gray-100 px-4">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
                                </svg>
                                <p className="text-base text-gray-900 font-semibold">Country - {userdetails.country}</p>
                            </div>
                        </div>
                         {/* <div className="flex items-start justify-between my-4 pb-3 border-b border-gray-100 px-4">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-600">
                                    <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clipRule="evenodd" />
                                </svg>
                                <p className="text-base text-gray-900 font-semibold">Date Registered - {new Date(userdetails.timestamp.seconds * 1000).toString() }</p>
                            </div>
                        </div>  */}
                        <div className="flex items-start justify-between my-4 pb-3 border-b border-gray-100 px-4">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-600">
                                    <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                                </svg>
                                <p className="text-base text-gray-900 font-semibold">Total Transactions - 0</p>
                            </div>
                            <a href="/account/history">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-gray-900 hover:text-gray-300 bg-gray-600 rounded-full p-1">
                                    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Profile