import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import Nav from '../Components/Nav';
function Withdrwal() {

    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1)
    const [coin, setcoin] = useState("BITCOIN")
    const [wallet, setwallet] = useState("")
    const [Amount, setAmount] = useState("")


    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };


    const confirmWitdrawal = async () => {

        if (Amount != "") {
            if (coin == "btc") {
                if (parseFloat(userdetails.Btcbalance) < parseFloat(Amount)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "balance not enough",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    await updatewithdrawalhistory()
                }
            } else if (coin == "eth") {
                if (parseFloat(userdetails.ethbalance) < parseFloat(Amount)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "balance not enough",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    await updatewithdrawalhistory()
                }
            } else if (coin == "xlm") {
                if (parseFloat(userdetails.xlmbalance) < parseFloat(Amount)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "balance not enough",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    await updatewithdrawalhistory()
                }
            } else if (coin == "xdc") {
                if (parseFloat(userdetails.xdcbalance) < parseFloat(Amount)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "balance not enough",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    await updatewithdrawalhistory()
                }
            } else {
                if (parseFloat(userdetails.xrpbalance) < parseFloat(Amount)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "balance not enough",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    await updatewithdrawalhistory()
                }
            }


        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "no Amount selected",
                footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    }


    const updatewithdrawalhistory = async () => {

        if (coin == "" || Amount == "" || wallet == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Select wallet, Coin , and Amount to procecced withdrawal",
                footer: '<a href="">Why do I have this issue?</a>'
            })
            return 0
        }
        // console.log(cointype)
        setloading(true)
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            Totalwithdrawal: increment,
            Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",

            }),
        });
        await updateUserBalance()
        await CreatWithdrawalObj()
    };

    const updateUserBalance = async (bal) => {

        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = await db.collection("users").doc(userids);

        if (coin == "btc") {
            const newEarings = parseFloat(userdetails.Btcbalance) - parseFloat(Amount);
            washingtonRef
                .update({
                    Btcbalance: newEarings,
                })
                .then(function () {
                    console.log("Document successfully up2dated!");
                    // alert("withdrawal was successful we will get back to you");
                    Swal.fire({
                        icon: 'success',
                        title: 'withdrawal was successful we will get back to you!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        } else if (coin == "eth") {
            const newEarings = parseFloat(userdetails.ethbalance) - parseFloat(Amount);
            washingtonRef
                .update({
                    ethbalance: newEarings,
                })
                .then(function () {
                    console.log("Document successfully up2dated!");
                    // alert("withdrawal was successful we will get back to you");
                    Swal.fire({
                        icon: 'success',
                        title: 'withdrawal was successful we will get back to you!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        } else if (coin == "xlm") {
            const newEarings = parseFloat(userdetails.xlmbalance) - parseFloat(Amount);
            washingtonRef
                .update({
                    xlmbalance: newEarings,
                })
                .then(function () {
                    console.log("Document successfully up2dated!");
                    // alert("withdrawal was successful we will get back to you");
                    Swal.fire({
                        icon: 'success',
                        title: 'withdrawal was successful we will get back to you!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        } else if (coin == "xdc") {
            const newEarings = parseFloat(userdetails.xdcbalance) - parseFloat(Amount);
            washingtonRef
                .update({
                    xdcbalance: newEarings,
                })
                .then(function () {
                    console.log("Document successfully up2dated!");
                    // alert("withdrawal was successful we will get back to you");
                    Swal.fire({
                        icon: 'success',
                        title: 'withdrawal was successful we will get back to you!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        } else {
            const newEarings = parseFloat(userdetails.xrpbalance) - parseFloat(Amount);
            washingtonRef
                .update({
                    xrpbalance: newEarings,
                })
                .then(function () {
                    console.log("Document successfully up2dated!");
                    // alert("withdrawal was successful we will get back to you");
                    Swal.fire({
                        icon: 'success',
                        title: 'withdrawal was successful we will get back to you!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        }

        // fetchuserdata();
    };
    const CreatWithdrawalObj = async () => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        let uobj = {
            fullname: userdetails.fullname,
            email: userdetails.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userid: userids,
            info: {
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",
            }
        };
        const userdetail1 = await db
            .collection("withdrawals")
            .add(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };

    function toggleNav() {
        const nav = document.getElementById("nav");
        let isNavOpen = false;
        const openButton = document.getElementById("open-button");
        const closeButton = document.getElementById("close-button");
        //   nav.classList.toggle("animate-slide");
        nav.classList.toggle("hidden");
        isNavOpen = !isNavOpen;

        openButton.classList.toggle("hidden");
        openButton.classList.toggle("animate-rotate");
        closeButton.classList.toggle("hidden");
        closeButton.classList.toggle("animate-rotate");
    }

    return (
        <div>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="stylesheet" href="/stylesheets/output.css" />
            <link rel="stylesheet" href="/stylesheets/dashboard.css" />
            <title>Qfsnesere | Dashboard</title>
            {/* SMARTSUPP */}
            {/* Smartsupp Live Chat script */}
            <noscript> Powered by &lt;a href=“https://www.smartsupp.com” target=“_blank”&gt;Smartsupp&lt;/a&gt;</noscript>
            <div className="min-h-screen relative bg-white">
                {/*DASHBOARD NAVIGATION*/}
                <Nav />
                {/* Flash Messages */}
                <div className="flex flex-col flex-wrap justify-center md:flex-row my-10">
                    <form className="p-2 overflow-hidden sm:rounded-md basis-2/4" action="/account/fund-account" encType="multipart/form-data" method="POST">
                        <div className="flex items-center space-x-2 mx-4">
                            <p className="text-gray-900 text-3xl md:text-2xl font-semibold">
                                Fund Withdrawal
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                            </svg>
                        </div>
                        <div>
                            <div className="px-4 py-5">
                                <div className="grid grid-cols-6 gap-3">
                                    <div className="col-span-12">
                                        <input onChange={(e) => { setAmount(e.target.value) }} type="number" name="amount" id="amount" required step="0.000000000000001" min="0.01" placeholder="Enter amount" className="text-black placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block px-3 py-2 border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500" />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="wallet" className="flex justify-between text-lg my-2 font-medium text-gray-700">
                                            Wallet
                                        </label>
                                        <select name="wallet" onChange={(e) => { setcoin(e.target.value) }} id required className="text-black placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block px-3 py-2 border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500" onchange="displayWalletAddress(this)">
                                            <option value>Select Wallet</option>
                                            <option value="eth">Eth </option>
                                            <option value="btc">Bitcoin </option>
                                            <option value="xrp">XRP </option>
                                            <option value="xlm">XLM </option>
                                            <option value="xdc">XDC </option>
                                        </select>
                                    </div>
                                    <div id="qrcode-container" className="col-span-12 hidden">
                                        <img id="qrcode-image" className="mx-auto w-1/3" src="/images/qrcode/btc.jpg" alt="" />
                                    </div>
                                    <input type="hidden" name="address" defaultValue id="address" required />
                                    <input type="hidden" name="method" defaultValue id="method" required />
                                    <div className="col-span-12">
                                        <input type="text" onChange={(e) => { setwallet(e.target.value) }} name="Wallet Address" id="Wallet Address" required step="0.000000000000001" min="0.01" placeholder="Enter Wallet Address" className="text-black placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block px-3 py-2 border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 px-4">
                                <button id="submit-btn" type="button" onClick={confirmWitdrawal} className="flex justify-between items-center w-full text-white font-semibold py-3 px-8 bg-black text-base transition-all my-2 rounded-full">
                                    Withdrwal
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 ml-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Withdrwal