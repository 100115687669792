import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import Nav from '../Components/Nav';

function TransactionHistory() {
    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)
    const [dataready, setdataready] = useState(false)
    /// use profits to display earnings too and also
    const [profits, setprofits] = useState(0)

    const [open, setOpen] = React.useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                    setloading(false)

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/");
                }
            });
        }
    }, []);


    
    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                    setdataready(true)
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };


    return (
        <div>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="stylesheet" href="https://globalquantledger.com/stylesheets/output.css" />
            <link rel="stylesheet" href="https://globalquantledger.com/stylesheets/dashboard.css" />
            <title>Qfsnesere | Dashboard</title>
            {/* SMARTSUPP */}
            {/* Smartsupp Live Chat script */}
            <noscript> Powered by &lt;a href=“https://www.smartsupp.com” target=“_blank”&gt;Smartsupp&lt;/a&gt;</noscript>
            <div className="min-h-screen relative bg-white">
                {/*DASHBOARD NAVIGATION*/}
                <Nav />
                {/* Flash Messages */}
                <div id="alert-3" className="flex px-2 py-4 my-4 mb-4 bg-green-100/30 border border-green-500" role="alert">
                    <div className="ml-3 text-sm font-normal text-green-300">Logged you in successfully!</div>
                </div>
                {/*GET LAST ITEM IN ARRAY*/}
                {/*CHECK IF VALUE EXISTS AND RENDER*/}
                <div className="flex flex-col flex-wrap md:flex-row">

                    {/* TRANSACTIONS */}
                    <section className="flex-col basis-3/5 border-2 border-black  self-stretch md:self-start items-center rounded-xl m-4 py-10 px-6">
                        <div className="flex flex-col space-y-2">
                            <div className="flex items-center space-x-2">
                                <p className="text-gray-900 text-base md:text-base font-semibold my-2">Recent  Activity
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700 font-medium rounded-full p-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                                </svg>
                            </div>
                            <div className="flex justify-between mb-1">
                                <p className="text-gray-900 text-base md:text-sm font-semibold">Type</p>
                                <p className="text-gray-900 text-base md:text-sm font-semibold">Amount</p>
                                <p className="text-gray-900 text-base md:text-sm font-semibold">Date</p>
                                <p className="text-gray-900 text-base md:text-sm font-semibold">Status</p>
                            </div>
                            {
                                dataready && (
                                    userdetails.withdrawalhistory.map((obj, i) => (
                                        <div className="flex justify-between mb-1">
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{obj.mode}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">${obj.amt}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{new Date(obj.date).toLocaleString()}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{obj.coin}</p>
                                        </div>

                                    ))

                                )
                            }
                            {
                                dataready && (
                                    userdetails.Deposithistory.map((obj, i) => (
                                        <div className="flex justify-between mb-1">
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{obj.mode}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">${obj.amt}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{new Date(obj.date).toLocaleString()}</p>
                                            <p className="text-gray-900 text-base md:text-sm font-semibold">{obj.coin}</p>
                                        </div>

                                    ))

                                )
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default TransactionHistory