import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import AppContainer from "./AppContainer";
import Dashboard from "./Pages/Dashboard";
import Deposit from "./Pages/Deposit";
import Withdrwal from "./Pages/Withdrwal";
import Seettings from "./Pages/Seettings";
import Profile from "./Pages/Profile";
import TransactionHistory from "./Pages/TransactionHistory";
import Swapcurrencies from "./Pages/Swapcurrencies";
import "./App.css"



function App() {

  return (
    <GlobalState>
      {/* <ToastContainer className="foo" /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AppContainer />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/settings" element={<Seettings />} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/transactions" element={<TransactionHistory />} /> 
          <Route path="/swapcurrencies" element={<Swapcurrencies />} /> 
          <Route path="/withdrawal" element={<Withdrwal />} />
        </Routes>
      </BrowserRouter>
    </GlobalState>
  );
}

export default App;
