import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyB783O0hflzWsWVFcSkxX32M0gwXDXnz6o",
  authDomain: "universalqfsledger.firebaseapp.com",
  databaseURL: "https://universalqfsledger-default-rtdb.firebaseio.com",
  projectId: "universalqfsledger",
  storageBucket: "universalqfsledger.appspot.com",
  messagingSenderId: "692575319836",
  appId: "1:692575319836:web:4d3035b88c2a3fa0b5ecd9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()